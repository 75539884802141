<template>
	<div id="resource-filter" class="overflow-hidden">
		<section class="text-white mb-60 md:mb-80 pt-95  px-5 md:px-30">
			<div class="container-fluid"> 
				<div class="row">

					<div class="col w-full sm:w-1/3 flex items-start mb-25 md:mb-0">
						<div class="flex items-center">
							<span class="mr-20">
								<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8 7L0.500001 13.9282L0.500001 0.071797L8 7Z" fill="white"/>
								</svg>
							</span>
							<span @click="show = !show" class="general-hover text-12 leading-23 uppercase tracking-small cursor-pointer">{{ name }}</span>
						</div>
					</div>

					<transition name="slideDown"> 

						<content-filter
						 v-if="!show"
						:tags="tags"
						placeholder="1"
						@updated="handleUpdate" 
						/>

					</transition>

				</div> 
			</div>
		</section>


		<section class="text-white px-5 md:px-30">  
			<div class="container-fluid"> 
				<div class="row">

					<div
					v-for="(item, i) in filteredProjects"
					:key="`project-${handelize(item.name)}-${i}`" 
					class="col w-full xs:w-1/2 md:w-4/12 pb-80 transition-opacity duration-300 fade-in"
					>
					
					<div class="w-full h-full"> 
						<a :href="item.url">
							<div class="itemsEntries relative md:h-0 md:pt-panel overflow-hidden">
								<img
									class="md:h-full w-full md:absolute md:top-0 md:left-0 md:object-cover lazyload"
									:src="image(item.image).small"
									:alt="image(item.image).alt"
									:data-src="image(item.image).url"
								>
							</div> 
							<div class="card-content mt-15">
								<span class="body-text block">{{ item.name }}</span>
								<span class="body-text block">{{ item.type }}</span>
							</div>
						</a> 
					</div>

				</div>

			</div>
		</div>
	</section>



</div>
</template> 

<script> 

	import ContentFilter from '@vc/ContentFilter';
	import ImgixClient from '@imgix/js-core';

	export default {
		name: 'ProjectFilter',
		components: {
			ContentFilter,
		},
		props: {
			 
			name: {
				type: String, 
				required: true,
			},
			tags: {
				type: Array,
				required: true,
			},
			projects: {
				type: Array, 
				required: true,
			},
		},
		data() {
			return {
				show: Boolean,
				loading: false,
				imgix: new ImgixClient({
					domain: 'matvin.imgix.net',
					secureURLToken: '60cc1691f1e828f82dacb86a', 
				}),
				filters: {
					tag: '',
				},
				shownProjects: [],
			};
		},
		computed:{
			filteredProjects(){

				if (this.projects.length && this.filters.tag !== '') {
					return this.projects.filter((item) => {
						return	item.category.includes(this.filters.tag);
					});

				}
				return this.projects; 
			}
		},
		watch: {
			filters: {
				handler(val) {
					this.loading = true;
					this.shownProjects = [];

					const valCheck = this.handelize(val.tag);

					if (valCheck === '') {
						this.shownProjects = this.projects;
						setTimeout(this.loading = false, 200);
						return;
					}
				},
				deep: true,
			},
		},

		mounted() {
			this.shownProjects = this.projects;
			this.filters.tag = this.tags[0].handle;
		},

		methods: { 
			handelize(str) {
				return str.replace('& ', '-').replace(/\s/g, '-').toLowerCase();
			},
			handleUpdate(value) {
				this.loading = true; 
				console.log(value);
				setTimeout(() => {
					this.filters.tag = value;
					this.loading = false;
					window.history.pushState({}, document.title, `/projects/${this.filters.tag}`);

					}, 200); 
			
			}, 
			imgixImg(img, options = { auto: 'compress' }) { 
				const newImg = img;
				if (img.path) {
					newImg.url = this.imgix.buildURL(newImg.path, options);
				} else {
					const newUrl = img.url.replace(window.location.hostname, '').replace(window.location.protocol, '').replace('//images', 'images');
					newImg.url = this.imgix.buildURL(newUrl, options);
				}
				newImg.url = this.imgix.buildURL(img.path, options);
				newImg.small = this.imgix.buildURL(img.path, { px: 100 }); 
				return newImg;
			},
			image(img) {
				let image;
				if (img) {
					image = img
				} else {
					[image] = img
				}
				if (window.location.hostname.includes('test')) {
					return image 
				}
				return this.imgixImg(image);
			},

		},

	};





</script> 
