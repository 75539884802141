var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-hidden", attrs: { id: "resource-filter" } },
    [
      _c(
        "section",
        { staticClass: "text-white mb-60 md:mb-80 pt-95  px-5 md:px-30" },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col w-full sm:w-1/3 flex items-start mb-25 md:mb-0"
                  },
                  [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c("span", { staticClass: "mr-20" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "8",
                              height: "14",
                              viewBox: "0 0 8 14",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M8 7L0.500001 13.9282L0.500001 0.071797L8 7Z",
                                fill: "white"
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "general-hover text-12 leading-23 uppercase tracking-small cursor-pointer",
                          on: {
                            click: function($event) {
                              _vm.show = !_vm.show
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.name))]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slideDown" } },
                  [
                    !_vm.show
                      ? _c("content-filter", {
                          attrs: { tags: _vm.tags, placeholder: "1" },
                          on: { updated: _vm.handleUpdate }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "text-white px-5 md:px-30" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.filteredProjects, function(item, i) {
              return _c(
                "div",
                {
                  key: "project-" + _vm.handelize(item.name) + "-" + i,
                  staticClass:
                    "col w-full xs:w-1/2 md:w-4/12 pb-80 transition-opacity duration-300 fade-in"
                },
                [
                  _c("div", { staticClass: "w-full h-full" }, [
                    _c("a", { attrs: { href: item.url } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "itemsEntries relative md:h-0 md:pt-panel overflow-hidden"
                        },
                        [
                          _c("img", {
                            staticClass:
                              "md:h-full w-full md:absolute md:top-0 md:left-0 md:object-cover lazyload",
                            attrs: {
                              src: _vm.image(item.image).small,
                              alt: _vm.image(item.image).alt,
                              "data-src": _vm.image(item.image).url
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content mt-15" }, [
                        _c("span", { staticClass: "body-text block" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "body-text block" }, [
                          _vm._v(_vm._s(item.type))
                        ])
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }