import { throttle } from "../../utilities/throttle.js";


const stickyNav = (function () {
	let $nav;
	let animating = false;
	let navPos;
	const init = function () {
		$nav = document.getElementById('main-nav');

		if ($nav !== undefined && $nav !== null) {
			navPos = $nav.getBoundingClientRect().y;
			const sticky = $nav.getAttribute('data-sticky');
			if (sticky == '"always"') {
				window.addEventListener('scroll', _alwaysSticky);
			} else {
				window.addEventListener('scroll', _handleSticky);
			}
		} else {
			console.warn('Sticky nav cant find nav'); 
		} 
	};

	const _handleSticky = throttle((e) => {
		if (window.scrollY >= window.innerHeight * 0.05 && $nav.matches('.nav-sticky') === false && !animating) {
			console.log('tests');
			_setSticky();
		} else if (window.scrollY <= window.innerHeight * 0.05 && $nav.matches('.nav-sticky') && !animating) {
			_removeSticky();
		}
	}, 200, { leading: true });
	const _setSticky = () => {
		animating = true;

		$nav.classList.add('nav-sticky--hide', 'nav-sticky');

		setTimeout(() => {
			$nav.classList.add('nav-sticky--show');
			$nav.classList.remove('nav-sticky--hide');
			animating = false;
		}, 200);
	};
	const _removeSticky = () => {
		animating = true;

		$nav.classList.add('nav-sticky--hide');

		setTimeout(() => {
			$nav.classList.remove('nav-sticky--hide', 'nav-sticky', 'nav-sticky--show');
			animating = false;
		}, 200);
	};
	const _alwaysSticky = throttle((e) => {
		if (window.scrollY >= navPos && $nav.matches('.nav-sticky') === false && !animating) {
			$nav.classList.add('nav-sticky--show', 'nav-sticky');
		} else if (window.scrollY <= navPos && $nav.matches('.nav-sticky') && !animating) {
			$nav.classList.remove('nav-sticky--show', 'nav-sticky');
		}
	}, 200, { leading: true });
	return {
		init,
	};
}());

export { stickyNav };
