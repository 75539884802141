

const peopleCollapse = (function () {
	let mobileContent; 
  
	const init = function () { 
		_getElements();
		_initEvents(); 
	};
	const _getElements = function () {
		mobileContent = Array.from(document.querySelectorAll('.people-collapse'));
	};
	const _initEvents = function () {  
		mobileContent.forEach((expander) => {
			const $toggle = expander.querySelector('.people-collapse__trigger');
			const $body = expander.querySelector('.people-collapse__content');
			
			$toggle.addEventListener('click', () => { _toggleOpen($body, $toggle); });
		});
	};
	const _toggleOpen = function ($el, $tog) {
		const options = { 
			duration: 500, 
			easing: 'ease-out', 
		};
 
		if ($el.classList.contains('hide')) {

			$tog.classList.add('open'); 
			$tog.innerHTML = 'Close';
			$el.classList.remove('hide');
			$el.classList.add('show');

 

		} else { 
		
		
			$tog.classList.remove('open'); 
			$tog.innerHTML = 'Read more +';
			$el.classList.add('hide');
			$el.classList.remove('show'); 


		}

	};


	return {
		init,
	};
}());

export { peopleCollapse };
