var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "col w-full sm:w-2/3 mb-25 md:mb-0 vue-filter" },
    [
      _c(
        "ul",
        { staticClass: "buttons" },
        _vm._l(JSON.parse(JSON.stringify(_vm.tags)), function(tag, i) {
          return _c(
            "li",
            {
              key: "resource-button-" + i,
              staticClass: "btn--tab general-hover body-text cursor-pointer",
              class: { active: _vm.filters.tag === _vm.handelize(tag.handle) },
              on: {
                click: function($event) {
                  return _vm.updateFilterTag(tag)
                }
              }
            },
            [_vm._v("\n\t\t\t" + _vm._s(tag.title) + "\n\t\t")]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }