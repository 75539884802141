import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import Vue from 'vue';
import Vuex from 'vuex';
import lazySizes from 'lazysizes';

import {
	scrollTo,
	stopScroll,
	resumeScroll,
} from './utilities/scrollControl';  

// VUE 
import SmoothReflow from '@vc/SmoothReflow';
import TeamModal from '@vc/teamModal';  
import ProjectFilter from '@vm/ProjectFilter';


import { expandCollapse } from './modules/expand-collapse.js'
import { navTrigger } from './modules/nav/navTrigger';
import { stickyNav } from '@m/nav/sticky-nav';
import glideInit from './modules/glideInit';
import { googleMaps } from './modules/googleMaps.js'
import { peopleCollapse } from './modules/peopleCollapse'; 
 

// stop lazy sizes from default initialising
lazySizes.cfg.init = false;

// App main 
const main = async () => {
	lazySizes.init();
	expandCollapse.init()
	navTrigger.init();
	glideInit.init(); 
	peopleCollapse.init(); 
	stickyNav.init(); 
	googleMaps.initMap();

	Vue.component('SmoothReflow', SmoothReflow);

	class InitVueComponent {
		constructor(selector, component) {
			this.selector = selector;
			this.components = component;
			this.instance = undefined;
			this.initialize();
		}

		initialize() {
			const component = this.components;
			let vueSelector;
			let vueEl;
			if (this.selector.includes('#')) {
				vueEl = document.querySelector(this.selector);
				if (vueEl) {
					this.instance = new Vue({
						el: this.selector,
						components: component,
					});
					// console.log(this.selector, this.instance);
				}
			} else {

				vueEl = document.querySelectorAll(this.selector);
				if (vueEl) {
					this.instance = [];
					vueEl.forEach((el) => {
						this.instance.push(new Vue({
							el,
							components: component,
						}));
					});
				}
			}
		}
	} 
	const VueTeamModal = new InitVueComponent('.team-modal', { TeamModal }); 
	const VueProjectFilter = new InitVueComponent('#vue-project-filter', { ProjectFilter });
};

//-------
//  click on a link to scroll to id 
//

  const scrollToHash = document.querySelectorAll('.js-scroll-to-hash')
  scrollToHash.forEach((item) => {
  	item.addEventListener('click', (e) => { 
  		e.stopPropagation()
  		e.preventDefault()

  		const href =
  		item.getAttribute('href') != null
  		? item.getAttribute('href')
  		: item.getAttribute('data-target')
      
  		let target = document.querySelector(href);
  		item.classList.add('active');
  		item.parentNode.children.forEach(sib => {
  			if (sib !== item){
  				sib.classList.remove('active');
  			}
  		});

  		let yOffset;

  		if(window.innerwidth <= 998){ 

  			yOffset = -110; 
 
  		} else { 

  			yOffset = -110;

  		} 
  		
  		const y = target.getBoundingClientRect().top + window.pageYOffset + yOffset;
  		window.scrollTo({top: y, behavior: 'smooth'});
  	})
  });
 

window.addEventListener("load", onLoadFunction); 

function onLoadFunction(e){
//do the magic you want 
onResizeFunction();// if you want to trigger resize function immediately, call it 

window.addEventListener("resize", onResizeFunction); 
}

function onResizeFunction (e){
	let vh = window.innerHeight * 0.01; 
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const swup = new Swup({ 

  animationSelector: '[class*="swup-transition-"]',
  plugins: [
  new SwupScrollPlugin({ 
  	doScrollingRightAway: false,
  	animateScroll: false, 
  }),
  ],
});


main();

 
swup.on('contentReplaced', () => {
	main();
});
