var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-modal-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "cursor-pointer overflow-hidden",
        on: {
          click: function($event) {
            _vm.open = true
          }
        }
      },
      [
        _c("img", {
          staticClass: "w-full lazyload",
          attrs: { src: "" + _vm.imgurl, alt: "" + _vm.imgname }
        })
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "py-25 cursor-pointer",
          on: {
            click: function($event) {
              _vm.open = true
            }
          }
        },
        [
          _c("span", { staticClass: "body-text block" }, [
            _vm._v(_vm._s(_vm.name))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "body-text block" }, [
            _vm._v(_vm._s(_vm.roll))
          ])
        ]
      ),
      _vm._v(" "),
      _c("ul", [
        _vm.mobile
          ? _c("li", { staticClass: "border-t-1 border-blacktint" }, [
              _c(
                "a",
                {
                  staticClass: "text-12 leading-12",
                  attrs: { href: "tel:" + _vm.mobile }
                },
                [_vm._v(_vm._s(_vm.mobile))]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.email
          ? _c("li", { staticClass: "border-t-1 border-blacktint" }, [
              _c(
                "a",
                {
                  staticClass: "text-12 leading-12",
                  attrs: { href: "mailTo:" + _vm.email }
                },
                [_vm._v(_vm._s(_vm.email))]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.blurb
          ? _c(
              "li",
              { staticClass: "border-t-1 border-b-1 border-blacktint" },
              [
                _c(
                  "button",
                  {
                    staticClass: "text-12 leading-12",
                    on: {
                      click: function($event) {
                        _vm.open = true
                      }
                    }
                  },
                  [_vm._v("More info")]
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "slide-in" } }, [
                  _vm.open
                    ? _c("div", { staticClass: "modal" }, [
                        _c(
                          "div",
                          { staticClass: "overflow-hidden modal-inner h-full" },
                          [
                            _c("div", { staticClass: "relative h-full" }, [
                              _c("div", { staticClass: "mb-20" }, [
                                _c("span", { staticClass: "body-text block" }, [
                                  _vm._v(_vm._s(_vm.name))
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "body-text block" }, [
                                  _vm._v(_vm._s(_vm.roll))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("ul", [
                                _vm.mobile
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "border-t-1 border-blacktint"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-12 leading-12",
                                            attrs: { href: "tel:" + _vm.mobile }
                                          },
                                          [_vm._v(_vm._s(_vm.mobile))]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.email
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "border-t-1 border-blacktint"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-12 leading-12",
                                            attrs: {
                                              href: "mailTo:" + _vm.email
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.email))]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "body-text py-20 border-t-1 border-b-1 border-blacktint"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.blurb) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "close absolute top-25 right-25 general-hover js-close-modal text-white z-30",
                                on: {
                                  click: function($event) {
                                    _vm.open = false
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "23",
                                      height: "22",
                                      viewBox: "0 0 23 22",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("line", {
                                      attrs: {
                                        x1: "0.880784",
                                        y1: "21.2929",
                                        x2: "20.8808",
                                        y2: "1.29289",
                                        stroke: "black",
                                        "stroke-width": "2"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("line", {
                                      attrs: {
                                        x1: "1.70711",
                                        y1: "1.29289",
                                        x2: "21.7071",
                                        y2: "21.2929",
                                        stroke: "black",
                                        "stroke-width": "2"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fade-in" } },
                  [
                    _vm.open
                      ? _c("backdrop", {
                          attrs: { open: _vm.open },
                          on: {
                            close: function($event) {
                              _vm.open = false
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }