

<template>

	<div class="vue-modal-wrapper"> 

		<div @click="open = true" class="cursor-pointer overflow-hidden">
			<img
				class="w-full lazyload"
				:src="`${imgurl}`"
				:alt="`${imgname}`" 
			>
		</div> 

		<div>
			<div @click="open = true" class="py-25 cursor-pointer">
				<span class="body-text block">{{ name }}</span> 
				<span class="body-text block">{{ roll }}</span> 
			</div>

			<ul>
				<li class="border-t-1 border-blacktint" v-if="mobile">
					<a :href="`tel:${mobile}`" class="text-12 leading-12">{{ mobile }}</a> 
				</li>
				<li class="border-t-1 border-blacktint" v-if="email">
					<a :href="`mailTo:${email}`" class="text-12 leading-12">{{ email }}</a>
				</li>
				<li class="border-t-1 border-b-1 border-blacktint" v-if="blurb">					
		       
				<button @click="open = true" class="text-12 leading-12">More info</button>

				<transition name="slide-in">  

					<div class="modal" v-if="open" >
						<div class="overflow-hidden modal-inner h-full">
							<div class="relative h-full">

								<div class="mb-20"> 
									<span class="body-text block">{{ name }}</span>
									<span class="body-text block">{{ roll }}</span>
								</div>

								<ul>
									<li class="border-t-1 border-blacktint" v-if="mobile">
										<a :href="`tel:${mobile}`" class="text-12 leading-12">{{ mobile }}</a>
									</li>
									<li class="border-t-1 border-blacktint" v-if="email">
										<a :href="`mailTo:${email}`" class="text-12 leading-12">{{ email }}</a>
									</li>
								</ul>

								<div class="body-text py-20 border-t-1 border-b-1 border-blacktint">
									{{ blurb }}
								</div>

							</div>

							<button class="close absolute top-25 right-25 general-hover js-close-modal text-white z-30" @click="open = false" > 
								<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<line x1="0.880784" y1="21.2929" x2="20.8808" y2="1.29289" stroke="black" stroke-width="2"/>
								<line x1="1.70711" y1="1.29289" x2="21.7071" y2="21.2929" stroke="black" stroke-width="2"/>
								</svg>
							</button> 
							
						</div>
					</div>
				</transition>

				<transition name="fade-in">
					<backdrop :open="open" v-if="open" @close="open = false"/>
				</transition>
 					
				</li>
			</ul>
			
		</div>


	</div>
</template>




<script>

	import Backdrop from '@vc/Backdrop';  

	export default {
		name: 'TeamModal',
		components: {
			Backdrop,
		},
		props: {
			name: {
				type: String, 
				required: true, 
			},
			roll: {
				type: String, 
				required: true, 
			},
			blurb: { 
				type: String, 
				required: true, 
			},
			mobile: {
				type: String, 
				required: true, 
			},
			email: {
				type: String, 
				required: true, 
			},
			imgurl: {
				type: String, 
				required: true, 
			},
			imgname: {
				type: String, 
				required: true, 
			},
		},
		watch:{
			open(oldVal, newVal){
				
				setTimeout(()=>{
					if(this.$refs.registerForm){
					 setLabelPositions(this.$refs.registerForm)
					}
				}, 300)
				
			}
		},
		data() {
			return {
				open : false,
			};
		},

		mounted() {
		},

		created() {
			
		},

		methods: {
			
		},
	};
</script>

