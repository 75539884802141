import Velocity from 'velocity-animate';

const expandCollapse = (function () {
	let expanders;

	const init = function () {
		_getElements();
		_initEvents();
	};
	const _getElements = function () {
		expanders = Array.from(document.querySelectorAll('.expand-collapse__item'));
	};
	const _initEvents = function () {
		expanders.forEach((expander) => {
			const $toggle = expander.querySelector('.expand-collapse__toggle');
			const $body = expander.querySelector('.expand-collapse__expander');
			const $icon = $toggle.querySelector('i');
			$toggle.addEventListener('click', () => { _toggleOpen($body, $icon); });
		});
	};
	const _toggleOpen = function ($el, $button) {
		const $state = $el.getAttribute('aria-expanded');
		const options = {
			duration: 350,
			easing: 'cubic-bezier(.76,0,.24,1)',
		};

		if ($state == 'true') {
			$el.classList.remove('open');
			$el.setAttribute('aria-expanded', 'false');
			Velocity($el, 'slideUp', options);
			if ($button != undefined) { 
				$button.classList.add('rotate-0');
				$button.classList.remove('rotate-45'); 
			}
		} else {
			let siblings;
			const parent = $el.closest('.expanders-container');
			// console.log(parent)
			if (parent != null) {
				siblings = parent.querySelectorAll('.expand-collapse');
			}

			Velocity($el, 'slideDown', options);
			$el.classList.add('open');
			$el.setAttribute('aria-expanded', 'true');

			if ($button != undefined) {
				$button.classList.remove('rotate-0');
				$button.classList.add('rotate-45');
			}

			if (siblings != undefined) {
				siblings.forEach((item) => {
					const $sibIcon = item.querySelector('i');
					const $sibExpander = item.querySelector('.expand-collapse__expander');
					if ($sibExpander !== $el) {
						$sibExpander.setAttribute('aria-expanded', 'false');
						$sibExpander.classList.add('closed');
						Velocity($sibExpander, 'slideUp', options);
						$sibIcon.classList.add('rotate-0');
						$sibIcon.classList.remove('rotate-45');
					}
				});
			}
		}

		if ($el.getAttribute('data-expander-image')) {
			_imageToggler($el);
		}
	};

	const _imageToggler = function ($el) {
		const target = $el.getAttribute('data-expander-image');
		const $state = $el.getAttribute('aria-expanded');
		let $img;
		const $imgs = Array.from($el.closest('.expander-container').querySelector('.expander-container__image-group').querySelectorAll('[data-expander-image]'));
		const $default = $el.closest('.expander-container').querySelector('[data-expander-image]');

		if (target != undefined && target != null) {
			$img = $el.closest('.expander-container').querySelector(`[data-expander-image="${target}"]`);
		} else {
			$img = $default;
		}

		if ($state == 'true') {
			$imgs.forEach((sibling) => {
				if ($img !== sibling) {
					sibling.classList.remove('opacity-100');
					sibling.classList.add('opacity-0');
				}
			});
			$img.classList.add('opacity-100');
			$img.classList.remove('opacity-0');
		} else {
			$img.classList.remove('opacity-100');
			$img.classList.add('opacity-0');

			$default.classList.add('opacity-100');
			$default.classList.remove('opacity-0');
		}
	};

	return {
		init,
	};
}());

export { expandCollapse };
