<template>
<nav class="col w-full sm:w-2/3 mb-25 md:mb-0 vue-filter">
	<ul class="buttons">
		<li
			v-for="(tag, i) in JSON.parse(JSON.stringify(tags))"
			:key="`resource-button-${i}`"
			class="btn--tab general-hover body-text cursor-pointer"
			:class="{ active : filters.tag === handelize(tag.handle) }"
			@click="updateFilterTag(tag)"
		>
			{{ tag.title }}
		</li> 
	</ul>
</nav>
</template>

<script >

import { throttle } from '@u/throttle';

export default {
	name: 'ContentFilter',
	components: {

	},
	props: {
		tags: {
			type: Array,
			required: true,
		},
	},
	emits: ['updated'],
	data() {
		return {
			windowWidth: '',
			loading: false,

			filters: {
				tag: '',
			},

		};
	},
	mounted() {
		this.filters.tag = JSON.parse(JSON.stringify(this.tags))[0].handle; 
	},
	methods: {
		handelize(str) {
			return str.replace('& ', '').replace(/\s/g, '-').toLowerCase();
		},
		updateFilterTag(selected) {
			this.filters.tag = this.handelize(selected.handle);
			this.$emit('updated', this.handelize(selected.handle));
		},
	},

};

</script>
